import { signOut, getUserData } from './auth.js';

export async function loadSideNav(currentPage, session, subscription, userData) {
  const userSection = document.getElementById('userSection');
  const skeletonLoader = document.getElementById('skeletonLoader');

  // Show skeleton loader
  skeletonLoader.classList.remove('hidden');
  
  let localUserData = userData;
  if (!localUserData && session && session.user) {
    localUserData = await getUserData(session.user.id);
  }

  // Get platform from URL or use the currentPage if not in a platform
  const currentplatform = getplatformFromUrl() || currentPage;
  currentPage = currentplatform;

  // Reusable isSelected function for highlighting selected links
  function isSelected(page) {
    if (page === 'jobs') {
      return currentPage === 'jobs' || currentPage === 'post-job';
    }
    if (page === 'apps') {
      return currentPage === 'apps' || isAppDetailsPage();
    }
    return currentPage === page;
  }

  // Update navigation links
  updateNavLinks(currentPage, isSelected);

  // Replace skeleton with actual user data
  if (session && subscription) {
    skeletonLoader.classList.add('hidden'); // Hide skeleton once data is ready
    userSection.innerHTML = `
      <div id="userProfile" class="flex items-center justify-between cursor-pointer bg-white border border-stone-200 p-2 rounded-md shadow-sm hover:bg-stone-100">
        <div class="flex items-center space-x-2">
          <img id="userProfileImage" src="${localUserData?.profile_picture_url}" class="w-6 h-6 rounded-full" alt="profile image">
          <span id="userName" class="text-stone-900 font-medium text-sm">${localUserData?.name || 'User'}</span>
        </div>
        <img src="https://api.overshooot.com/storage/v1/object/public/assets/icons/arrow-down-ss-line.svg" class="h-4 w-4" alt="dropdown icon">
      </div>
      <div id="profileDropdown" class="absolute right-4 left-4 mt-2 font-medium bg-white rounded-md shadow-sm border border-stone-200 p-1.5 z-50 hidden">
        <div id="userEmail" class="p-2 text-sm text-stone-500 mb-1">${localUserData?.email || ''}</div>
        <a href="/" id="logoutButton" class="block w-full p-2 text-sm text-stone-900 hover:bg-stone-100 rounded-md">Logout</a>
      </div>
    `;
  } else {
    skeletonLoader.classList.add('hidden'); // Hide skeleton even if no user data
    userSection.innerHTML = `
      <a href="/new-session" class="block w-full text-center text-sm font-medium py-3 px-2 bg-stone-900 text-white rounded-md hover:bg-stone-800 transition-colors duration-200">
        Log in
      </a>
    `;
  }

  // Show or hide Get Pro section
  const getProSection = document.getElementById('getProSection');
  if (subscription) {
    getProSection.style.display = 'none';
  } else {
    getProSection.style.display = 'block';
  }

  // Add event listener for logout
  const logoutButton = document.getElementById('logoutButton');
  if (logoutButton) {
    logoutButton.addEventListener('click', async (e) => {
      e.preventDefault();
      await signOut();
      window.location.href = '/';
    });
  }

  // Setup event listeners
  setupEventListeners();
}

function updateNavLinks(currentPage, isSelected) {
  const links = document.querySelectorAll('nav a[id^="link-"]');
  links.forEach(link => {
    const pageId = link.id.replace('link-', '');
    const selected = isSelected(pageId);
    updateNavLink(pageId, selected);
  });
}

function updateNavLink(pageId, selected) {
  const linkElement = document.getElementById(`link-${pageId}`);
  const iconElement = document.getElementById(`icon-${pageId}`);

  if (linkElement) {
    if (selected) {
      // Apply white background and text color to the link
      linkElement.classList.add('bg-stone-900', 'text-white', 'hover:bg-stone-800');
      linkElement.classList.remove('hover:bg-stone-100');
    } else {
      // Reset to default background and text color
      linkElement.classList.remove('bg-stone-900', 'text-white', 'hover:bg-stone-800');
      linkElement.classList.add('hover:bg-stone-100');
    }
  }

  if (iconElement) {
    if (selected) {
      // Apply selected state by making the icon white
      iconElement.classList.add('filter', 'invert');
    } else {
      // Remove selected state
      iconElement.classList.remove('filter', 'invert');
    }
  }
}

function getplatformFromUrl() {
  const pathParts = window.location.pathname.split('/');
  const validPlatforms = ['ios', 'android', 'web', 'watchos'];

  if (pathParts.length > 2 && pathParts[1] === 'browse' && validPlatforms.includes(pathParts[2])) {
      return pathParts[2]; // This will return 'ios', 'android', 'web', or 'watchos'
  }
  return null; // Default to null if no valid platform found
}

function isAppDetailsPage() {
  const pathParts = window.location.pathname.split('/').filter(Boolean);
  
  if (pathParts.length >= 1 && pathParts.length <= 4 &&
      !['browse', 'motion-in-motion', 'jobs', 'post-job', 'apps', 'getpro', 'new-session', 'new-user'].includes(pathParts[0])) {
    
    if (pathParts.length === 1) return true;
    
    if (['ios', 'android', 'web'].includes(pathParts[1])) {
      if (pathParts.length === 2) return true;
      if (pathParts.length === 3 && ['flows', 'interactions'].includes(pathParts[2])) return true;
      if (pathParts.length === 4 && ['flows', 'interactions'].includes(pathParts[2]) && pathParts[3] === 'all') return true;
    }
  }
  
  return false;
}

function setupEventListeners() {
  const userProfile = document.getElementById('userProfile');
  const profileDropdown = document.getElementById('profileDropdown');

  if (userProfile && profileDropdown) {
    userProfile.addEventListener('click', (event) => {
      event.stopPropagation();
      profileDropdown.classList.toggle('hidden');
    });

    document.addEventListener('click', (event) => {
      if (!userProfile.contains(event.target) && !profileDropdown.contains(event.target)) {
        profileDropdown.classList.add('hidden');
      }
    });
  }
}

// Call this function when the page loads and after navigation
document.addEventListener('DOMContentLoaded', () => {
  const currentplatform = getplatformFromUrl() || 'home'; // Assuming 'home' as default
  updateNavLinks(currentplatform, (page) => {
    if (page === 'jobs') {
      return currentplatform === 'jobs' || currentplatform === 'post-job';
    }
    if (page === 'apps') {
      return currentplatform === 'apps' || isAppDetailsPage();
    }
    return currentplatform === page;
  });
});